import ContractList from "./list";
import ContractShow from "./show";


if (document.body.getAttribute("data-current-path") === "customer/contracts") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new ContractList();
    } else if(action === "show") {
        new ContractShow();
    }
}